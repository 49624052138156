<template>
  <div>
    <v-dialog v-model="showModal" max-width="900px" persistent>
      <v-form ref="form" v-model="valid">
        <v-card :loading="loading">
          <v-card-title>
            <span class="headline">{{ title }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <div>
                <v-row>
                  <v-col offset-md="4"><avatar></avatar></v-col>
                </v-row>

                <v-row>
                  <v-text-field
                    v-model="$store.state.editedUser.name"
                    label="Nome"
                    :rules="rulesName"
                  ></v-text-field>
                </v-row>

                <v-row>
                  <v-text-field
                    v-model="$store.state.editedUser.email"
                    label="Email"
                    :rules="rulesEmail"
                  ></v-text-field>
                </v-row>

                <v-row>
                  <v-text-field
                    v-model="$store.state.editedUser.source"
                    label="Link do site"
                  ></v-text-field>
                </v-row>

                <v-row>
                  <v-menu
                    right
                    :offset-x="true"
                    v-model="menu"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :color="$store.state.editedUser.color"
                        elevation="2"
                        dark
                        >Cor</v-btn
                      >
                    </template>
                    <v-color-picker
                      v-model="$store.state.editedUser.color"
                      dot-size="25"
                      show-swatches
                      mode="hexa"
                    ></v-color-picker>
                    
                  </v-menu>
                </v-row>

                <v-row class="pt-5">
                  <v-autocomplete v-model="templates"  hide-details outlined chips small-chips multiple label="Templates" :items="template">
                    <template #item={item} >
                      <span class="pa-2"><v-img :src="'/img/'+item.replace(' ', '')+'.jpg'" width="20"></v-img></span>
                      <span>{{item}}</span>
                    </template>
                  </v-autocomplete>
                  <!-- <v-select v-model="$store.state.editedUser.template" :items="['Template1', 'Template2', 'Template3', 'Template4', 'Template5']" label="Template Padrão" dense >
                    <template #item={item} >
                      <span class="pa-2"><v-img :src="'/img/'+item+'.jpg'" width="20"></v-img></span>
                      <span>{{item}}</span>
                    </template>
                  </v-select>
                  <span class="pa-2"><v-img :src="'/img/'+$store.state.editedUser.template+'.jpg'" width="50"></v-img></span> -->
                </v-row>

                <v-row>
                  <v-select
                    @change="selectSimple"
                    :items="$store.state.accountTypes"
                    v-model="$store.state.editedUser.type"
                    label="Tipo de conta"
                    :rules="rulesType"
                  ></v-select>
                </v-row>

                <v-row>
                  <v-select
                    @change="selectedMultiple"
                    chips
                    multiple
                    :items="$store.state.routes"
                    v-model="$store.state.editedUser.descriptions"
                    label="Acessos"
                    item-text="description"
                  ></v-select>
                </v-row>
              </div>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" :loading="loading" text @click="save()"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { createUser, updateUser } from "../../services/users";
import avatar from "./Avatar";
export default {
  components: { avatar },
  props: {
    fields: { type: Array, defalut: [{}] },
    edit: { type: Boolean, defalut: false },
  },
  data: () => ({
    template: ['Template 1', 'Template 2', 'Template 3', 'Template 4', 'Template 5'],
    menu: false,
    valid: false,
    showModal: true,
    loading: false,
    color: "#A3A3A3",
    showColors: false,
    rulesName: [(val) => val.length > 0 || "Nome é obrigatório!"],
    rulesEmail: [(val) => val.length > 0 || "Email é obrigatório!"],
    rulesType: [
      (val) => (val || "").length > 0 || "Tipo de conta é obrigatório!",
    ],
  }),
  computed: {
    templates: {
      get(){
        return this.$store.state.editedUser.templates || []
      },
      set(value){
        this.$store.state.editedUser.templates = value
      }
    },
    fieldsData() {
      return this.fields.filter((field) => field.type);
    },
    title() {
      return !this.edit
        ? "Novo usuário: " + (this.$store.state.editedUser.email || "")
        : "Usuário: " + this.$store.state.editedUser.email;
    },
  },
  methods: {
    selectSimple(data) {
      this.$store.state.editedUser.descriptions =
        this.$store.state.routes.filter((route) =>
          route.accessGroup.includes(data)
        );
      this.$store.state.editedUser.routes =
        this.$store.state.editedUser.descriptions;
      this.$store.state.editedUser.totalRoutes =
        this.$store.state.descriptions.length;
    },
    selectedMultiple(data) {
      const dataField = data.reduce(
        (acc, item) => [
          ...acc,
          ...this.$store.state.routes.filter(
            (data) => data.description == item
          ),
        ],
        []
      );
      this.$store.state.editedUser.routes = dataField;
      this.$store.state.editedUser.totalRoutes = dataField.length;
    },
    validateFields() {
      this.$refs.form.validate();
    },
    async create() {
      this.validateFields();
      if (!this.valid) return (this.loading = false);
      await createUser(this.$store.state.editedUser).catch(console.error);
      this.$store.state.users.push(this.$store.state.editedUser);
      this.loading = false;
      this.close();
    },
    async save() {
      this.loading = true;
      if (!this.edit) return this.create();
      await updateUser(this.$store.state.editedUser);
      this.close();
    },
    close() {
      this.loading = false;
      this.showModal = false;
      this.$store.state.editedUser = Object.assign(
        {},
        this.$store.state.editedUserReset
      );
      this.$store.state.descriptions = [];
      setTimeout(() => {
        this.$store.state.dataTable.showModal = false;
      }, 80);
    },
  },
  watch: {
    showModal() {
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>