<template>
  <UsersTable></UsersTable>
</template>

<script>
import UsersTable from '@/components/userTable/UsersTable.vue'
export default {
  name: 'Users',
  components: {UsersTable}
}
</script>
