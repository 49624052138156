<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="$store.state.users"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Procurar Usuários"
          class="mx-4"
        ></v-text-field>

        <v-toolbar flat color="white">
          <v-toolbar-title>Usuários</v-toolbar-title>

          <v-spacer></v-spacer>

          <tooltip @click="newUser"> </tooltip>

          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
      </template>
      
      <template v-slot:item.logo="{ item }">
        <v-avatar>
          <img :src="`${item.logo || '/img/logo1.jpg'}`" alt="Logo" />
        </v-avatar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    
      <template v-slot:item.color="{ item }">
        <v-chip :color="item.color" dark>{{ item.color }}</v-chip>
      </template>
    </v-data-table>
    <Modal
      :edit="this.$store.state.editedIndex"
      :fields="headers"
      v-if="$store.state.dataTable.showModal"
    >
    </Modal>
  </div>
</template>

<script>
import { deleteUser, getUsers } from "../../services/users";
import tooltip from "./tooltip.vue";
import Modal from "./Modal";
export default {
  components: { tooltip, Modal },
  name: "UsersTable",

  data() {
    return {
      dialog: false,
      ttt: 1,
      search: "",
      headers: [
        {
          text: "Logo",
          align: "start",
          filterable: false,
          value: "logo",
          sortable: false,
          type: "avatar",
        },
        {
          text: "Nome",
          value: "name",
          type: "text",
          rules: ["Nome é obrigatório"],
        },
        {
          text: "Email",
          value: "email",
          type: "text",
          rules: ["Email é obrigatório"],
        },
        { text: "Color", value: "color", type: "color" },
        { text: "Link do site", value: "source", type: "text" },
        {
          text: "Tipo de conta",
          value: "type",
          store: "accountTypes",
          rules: ["Tipo de conta é obrigatório"],
          type: "selectSimple",
        },
        {
          text: "Acessos",
          value: `totalRoutes`,
          store: "routes",
          storeValue: "description",
          type: "selectMultiple",
        },
        { text: "Ações", value: "actions", sortable: true },
      ],
      desserts: [],
      editedItem: {},
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    newUser() {
      this.$store.state.editedIndex = false;
      this.$store.state.editedUser = Object.assign(
        {},
        this.$store.state.editedUserReset
      );
      this.openModal();
    },
    openModal() {
      this.$store.state.dataTable.showModal = true;
    },
    getColor(calories) {
      if (calories > 400) return "red";
      else if (calories > 200) return "orange";
      else return "green";
    },
    editItem(item) {
      this.$store.state.editedIndex = true;
      this.$store.state.editedUser = item;
      this.$store.state.editedUser.descriptions = item.routes.map(
        (route) => route.description
      ).sort((a, b) => (a.description < b.description)?-1:1);
      this.openModal();
    },
    async deleteItem(item) {
      if (item.type == "Administrador") {
        const admins = this.$store.state.users.filter(
          (user) => user.type == item.type
        );
        if (admins.length == 1)
          return alert("🚫 Você precisa ao menos um Administrador cadastrado");
      }
      const index = this.$store.state.users.indexOf(item);
      const email = this.$store.state.users[index].email
      if(confirm("Are you sure you want to delete this item?")){
        await deleteUser(email)
        this.$store.state.users.splice(index, 1);
      }
        
    },
  },
  mounted() {
    if(!this.$store.state.users.length){
    getUsers().then((data) => {
        return (this.$store.state.users = data.map((item) => ({
          ...item,
          totalRoutes: item.routes.length,
        })));
      });
    }
  },
};
</script>